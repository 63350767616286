import React from 'react';

import Carousel from 'react-elastic-carousel';

import image1 from '../../assets/images/others/photo-11.jpg';
import image2 from '../../assets/images/others/photo-6.jpg';
import image3 from '../../assets/images/others/photo-15.jpg';
import image4 from '../../assets/images/others/photo-8.jpg';
import image5 from '../../assets/images/others/photo-7.jpg';
import image6 from '../../assets/images/others/photo-5.jpg';

import './index.css';

const GallerySlider = () => {
	const breakPoints = [
		{ width: 1, itemsToShow: 1 },
		{ width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
		{ width: 850, itemsToShow: 3 },
		{ width: 1150, itemsToShow: 4, itemsToScroll: 2 },
		{ width: 1450, itemsToShow: 5 },
		{ width: 1750, itemsToShow: 6 },
	];
	return (
		<div className="container">
			<Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={5000}>
				<div className="carousel-image-area">
					<img src={image1} alt="" />
				</div>
				<div className="carousel-image-area">
					<img src={image2} alt="" />
				</div>
				<div className="carousel-image-area">
					<img src={image3} alt="" />
				</div>
				<div className="carousel-image-area">
					<img src={image4} alt="" />
				</div>
				<div className="carousel-image-area">
					<img src={image5} alt="" />
				</div>
				<div className="carousel-image-area">
					<img src={image6} alt="" />
				</div>
			</Carousel>
		</div>
	);
};

export default GallerySlider;
