import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

const Course = ({ courses }) => {
	return (
		<div className="courses-container">
			{courses.map((course) => {
				return (
					<Link
						to={`/courses/${course.category}/${course.slug}`}
						key={course.id}
					>
						<div className="course-container">
							<p>{course.title}</p>
							<img className="course-img" src={course.image} alt="" />
						</div>
					</Link>
				);
			})}
		</div>
	);
};

export default Course;
