import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import img1 from '../../assets/images/others/photo-13.jpg';
import img2 from '../../assets/images/others/photo-9.jpg';
import img3 from '../../assets/images/others/photo-11.jpg';
import GallerySlider from '../../components/GallerySlider';

const About = () => {
	window.scrollTo({
		top: 0,
	});
	return (
		<section className="section-container foot-cont">
			<div className="section-nav">
				<h1>About Us</h1>
			</div>
			<div className="container about-section">
				<div className="left-section">
					<img src={img1} className="position-img img-top" alt="" />
					<img src={img1} className="position-img img-middle" alt="" />
					<img src={img2} className="position-img alpha-img" alt="" />
					<img src={img3} className="position-img img-bottom" alt="" />
				</div>
				<div className="about-content">
					<p>
						N-TECH Systems is an Innovative IT Company. We empower young people
						with the relevant IT programs for securing jobs and to be
						self-reliant. N-TECH offers 80% Practicals class and hand-on
						training on all regular and Professional Courses which includes:{' '}
						<Link to="/courses/networking/networking">
							<b>Computer Networking</b>
						</Link>
						,{' '}
						<Link to="/courses/design/graphic-design-branding">
							<b>Graphics Design</b>
						</Link>{' '}
						,
						<Link to="/courses/web/web-design">
							<b>Web Design</b>
						</Link>
						,{' '}
						<Link to="/courses/software/programming">
							<b>Software Development</b>
						</Link>
						,
						<Link to="/courses/cloud/cloud-computing">
							<b> Cloud computing </b>
						</Link>
						,{' '}
						<Link to="/courses/engineering/computer-engineering">
							<b>Computer Engineering</b>
						</Link>{' '}
						e.t.c.
					</p>
					<p>
						We also deliver world-class IT Consultancy services to Individual
						Businesses, Institutions, Company and Organizations. We are an
						innovative company providing professional IT Solutions which
						includes: Web Development, School Management Systems, Hospital
						Management System, E-Library, Networking, Internet Installations,
						E-learning e.t.c. with quality customer support system.
					</p>
				</div>
			</div>
			<GallerySlider />
		</section>
	);
};

export default About;
