import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { GiHamburgerMenu } from 'react-icons/gi';

import logo from '../../assets/images/logo.png';

import './index.css';

const Navbar = () => {
	const [showNav, setShowNav] = useState(false);

	const linksContainerRef = useRef(null);
	const linksRef = useRef(null);

	useEffect(() => {
		if (showNav) {
			linksContainerRef.current.style.height = '100%';
		} else {
			linksContainerRef.current.style.height = '0px';
		}
	}, [showNav]);
	return (
		<div className="nav-container">
			<div className="mobile-nav">
				<Link to="/">
					<img src={logo} className="logo" alt="n-tech logo" />
				</Link>
				<GiHamburgerMenu
					onClick={() => setShowNav(!showNav)}
					className="hamburger-icon"
				/>
			</div>
			<div className="nav-links-container" ref={linksContainerRef}>
				<ul
					className="nav-links"
					onClick={() => setShowNav(!showNav)}
					ref={linksRef}
				>
					<li>
						<Link className="link" to="/">
							Home
						</Link>
					</li>
					<li>
						<Link className="link" to="/about">
							About
						</Link>
					</li>
					<li>
						<Link className="link" to="/courses">
							Courses
						</Link>
					</li>
					<li>
						<Link className="link" to="/services">
							Services
						</Link>
					</li>
					<li>
						<Link className="link" to="/contact">
							Contact
						</Link>
					</li>
					<li>
						<a href="https://ntechwifi.com.ng/" className="link cta">
							N-TECH WIFI
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Navbar;
