import React from 'react';

import { useParams } from 'react-router-dom';

import { courses } from '../Courses/data';
import { aboutCourses } from '../Courses/data';

import './index.css';

const CourseDetails = () => {
	window.scrollTo({
		top: 0,
	});

	const { category, slug } = useParams();
	const courseDetail = courses.filter(
		(data) => data.category === category && data.slug === slug
	);
	const aboutCourse = aboutCourses.filter(
		(about) => about.category === category
	);

	return (
		<section className="section-container foot-cont">
			<div className="section-nav">
				<h1>{courseDetail[0].title}</h1>
			</div>
			<div className="course-details container">
				<div className="left-content">
					<h2>About the Course</h2>
					<p>{aboutCourse[0].about}</p>
					<h3>What you will learn:</h3>
					<ul>
						{courseDetail[0].topics.map((topic, index) => {
							return <li key={index}>{topic}</li>;
						})}
					</ul>
					<p>
						<span>Certificate:</span>
						{courseDetail[0].certificate}
					</p>
				</div>
				<div className="right-content">
					<h2>Details</h2>
					<p>
						<span>Duration:</span>
						{courseDetail[0].duration}
					</p>

					<h3 className="location">Locations</h3>
					<p>
						<span>Ondo Office Address: </span>
						<br />
						68, Adjacent Jubilee Grammar School, Surulere, Ondo City, Ondo State
					</p>
					<p>
						<span>Akure Office Address: </span>
						<br />
						205, Oyemekun Road, Lafe Junction, Akure, Ondo State.
					</p>
				</div>
			</div>
		</section>
	);
};

export default CourseDetails;
