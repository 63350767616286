export const courses = [{
    id: 1,
    category: 'design',
    title: 'Graphic Design & Branding',
    slug: 'graphic-design-branding',
    image: require('./../../assets/images/branding.png'),
    topics: [
      'CorelDRAW',
      'Adobe Illustrator',
      'Photoshop'
    ],
    certificate: 'ACP in Visual Design Certification',
    duration: '12 weeks'
  },
  {
    id: 2,
    category: 'design',
    title: 'Autodesk',
    slug: 'autodesk',
    image: require('./../../assets/images/autodesk.png'),
    topics: [
      'AutoCAD',
      'Revit',
      'Inventor',
      'Civil3D'
    ],
    certificate: 'Autodesk Certification',
    duration: '12 weeks'
  },
  {
    id: 3,
    category: 'web',
    title: 'PHP Framework',
    slug: 'php-framework',
    image: require('./../../assets/images/php.png'),
    topics: [
      'Html',
      'CSS/Bootstrap',
      'JavaScript',
      'jquery',
      'PHP/Laravel',
      'MySQL'
    ],
    certificate: 'Web development Certification',
    duration: 'NIL'
  },
  {
    id: 4,
    category: 'web',
    title: 'JavaScript Framework',
    slug: 'javascript-framework',
    image: require('./../../assets/images/javascript.png'),
    topics: [
      'Html',
      'CSS/Bootstrap',
      'JavaScript/React',
      'Node JS',
      'Mongo DB'
    ],
    certificate: 'Web development Certification',
    duration: 'NIL'
  },
  {
    id: 5,
    category: 'web',
    title: 'Python Framework',
    slug: 'python-framework',
    image: require('./../../assets/images/python.png'),
    topics: [
      'Html',
      'CSS/Bootstrap',
      'Python/Django',
      'MySQL',
    ],
    certificate: 'Web development Certification',
    duration: 'NIL'
  },
  {
    id: 6,
    category: 'web',
    title: 'Web Design',
    slug: 'web-design',
    image: require('./../../assets/images/web-design.png'),
    topics: [
      'Html',
      'CSS',
      'Bootstrap',
      'JavaScript '
    ],
    certificate: 'Web development Certification',
    duration: 'NIL'
  },
  {
    id: 7,
    category: 'web',
    title: 'Database',
    slug: 'database',
    image: require('./../../assets/images/database.png'),
    topics: [
      'Oracle is MySQL Database'
    ],
    certificate: 'Oracle SQL, Admins & Certification Oracle - MySQL & Certification',
    duration: '20 weeks'
  },
  {
    id: 8,
    category: 'other',
    title: 'Digital Marketing',
    slug: 'digital-marketing',
    image: require('./../../assets/images/digital-mkt.png'),
    topics: [
      'SEO Analysis',
      'Social Media & Email Marketing',
      'Constant Marketing',
      'Introduction to WordPress'
    ],
    certificate: 'DMI Certification',
    duration: 'NIL'
  },
  {
    id: 9,
    category: 'other',
    title: 'Project Management',
    slug: 'project-management',
    image: require('./../../assets/images/project-mgt.png'),
    topics: [
      'PRINCE 2',
      'PMP'
    ],
    certificate: 'CAPM, PMP & PRINCE2 Certification',
    duration: '12 Weeks'
  },
  {
    id: 10,
    category: 'other',
    title: 'ICT Fundamentals',
    slug: 'ict-fundamentals',
    image: require('./../../assets/images/ict.png'),
    topics: [
      'Microsoft Suits',
      'OS Introduction',
      'Internet Essentials ISAS',
      'Database',
      'Graphics Intro'
    ],
    certificate: 'Microsoft Offices Specialists & Certification, COMPTIA IT Fundamental, Certification',
    duration: '12 Weeks'
  },
  {
    id: 11,
    category: 'other',
    title: 'Data Analysis',
    slug: 'data-analysis',
    image: require('./../../assets/images/data-analysis.png'),
    topics: [
      'SAS Programming',
      'SQL in SAS '
    ],
    certificate: 'SAS Certification',
    duration: '12 Weeks'
  },
  {
    id: 12,
    category: 'software',
    title: 'Programming',
    slug: 'programming',
    image: require('./../../assets/images/programming.png'),
    topics: [
      'JAVA',
      'C/C++',
      'Python',
      'VB/C#'
    ],
    certificate: 'ORACLE-JAVA, Open &DG & PeopleCert Certification',
    duration: '12 Weeks'
  },
  {
    id: 13,
    category: 'engineering',
    title: 'Computer Engineering',
    slug: 'computer-engineering',
    image: require('./../../assets/images/engineering.png'),
    topics: [
      'Hardware',
      'Networking',
      'OS System'
    ],
    certificate: 'CompTIA A+, Core 1 & 2 Certification',
    duration: '20 Weeks'
  },
  {
    id: 14,
    category: 'software',
    title: 'Mobile Application',
    slug: 'mobile-application',
    image: require('./../../assets/images/mobile-app.png'),
    topics: [
      'React Native',
      'Flutter'
    ],
    certificate: 'Android ATC Certification',
    duration: '12 Weeks'
  },
  {
    id: 15,
    category: 'cloud',
    title: 'Cloud Computing',
    slug: 'cloud-computing',
    image: require('./../../assets/images/cloud.png'),
    topics: [
      'Amazon Web Services',
      'Microsoft Azure'
    ],
    certificate: 'AWS & Microsoft Azure Certification',
    duration: '12 Weeks'
  },
  {
    id: 16,
    category: 'networking',
    title: 'Networking',
    slug: 'networking',
    image: require('./../../assets/images/networking.png'),
    topics: [
      'CISCO Certified Network Associate'
    ],
    certificate: 'CISCO CCNA 200 – 301 Certification',
    duration: '12 Weeks'
  },
  {
    id: 17,
    category: 'networking',
    title: 'Cyber Security',
    slug: 'cyber-security',
    image: require('./../../assets/images/cyber-sec.png'),
    topics: [
      'Linux',
      'Networking',
      'Ethical Hacking & Counter Measures'
    ],
    certificate: 'EC-Council or EXIN Certification',
    duration: '12 Weeks'
  }
]

export const aboutCourses = [{
    category: 'web',
    about: 'Web development is a broad term for the work involved in developing a web site for the Internet (World Wide Web) or an intranet (a private network). Web development can range from developing the simplest static single page of plain text to the most complex web-based internet applications, electronic businesses, and social network services. N-TECH provides a platform where you can be trained to be a professional Web Developers.'
  },
  {
    category: 'networking',
    about: 'Cisco Networking Academy, a global education initiative from Cisco Systems, offers networking programs, (Cisco Certified Network Associate) CCNA and (Cisco Certified Network Professional) CCNP courses, which prepare students for the certification exams of CISCO and other computer-related courses. N-TECH provides a platform where you can be trained for these certification.'
  },
  {
    category: 'design',
    about: 'User Experience Design, User Interface, Design Thinking, Strategy, Figma, wireframe, Mockups, prototypes.The UI, “User Interface” is the graphical layout of an application. UI designers create the look and feel of an application’s user interface including screen layout, transitions, interface animations, and every single micro-interaction. The UX, “User Experience” of the app is determined by how easy or difficult it is to interact with the user interface elements that the UI designers have created.'
  },
  {
    category: 'networking',
    about: 'Cisco Networking Academy, a global education initiative from Cisco Systems, offers networking programs, (Cisco Certified Network Associate) CCNA and (Cisco Certified Network Professional) CCNP courses, which prepare students for the certification exams of CISCO and other computer-related courses. N-TECH provides a platform where you can be trained for these certification.'
  },
  {
    category: 'other',
    about: ' N-TECH stands to give you the technology edge by giving you the best professional IT training and empower you to secure that your dream job to start your career. We provides timely and Hand-On IT training that bridges the gap between youth development, ICT skill acquisition and job creation which are the prime engine of growth in most of the developing world.'
  },
  {
    category: 'software',
    about: ' Software development is the process of computer programming, documenting, testing, and bug fixing involved in creating and maintaining applications. Software can be developed for a variety of purposes, the three most common being to meet specific needs of a specific client/business (the case with custom software), to meet a perceived need of some set of potential users (the case with commercial and open source software), or for personal use (e.g. a scientist may write software to automate a mundane task).'
  },
  {
    category: 'engineering',
    about: ' N-TECH stands to give you the technology edge by giving you the best professional IT training and empower you to secure that your dream job to start your career. We provides timely and Hand-On IT training that bridges the gap between youth development, ICT skill acquisition and job creation which are the prime engine of growth in most of the developing world.'
  },
  {
    category: 'cloud',
    about: ' N-TECH stands to give you the technology edge by giving you the best professional IT training and empower you to secure that your dream job to start your career. We provides timely and Hand-On IT training that bridges the gap between youth development, ICT skill acquisition and job creation which are the prime engine of growth in most of the developing world.'
  }
]