import React from 'react';
import { Link } from 'react-router-dom';

import AboutImg from '../../assets/images/others/photo-10.jpg';

import './index.css';

const AboutUs = () => {
	return (
		<section id="about" className="about-container container">
			<div className="section-header">
				<h3>About Us</h3>
				<hr className="line" />
			</div>
			<div className="about-content-container">
				<div className="about-img">
					<img src={AboutImg} alt="about-img" />
				</div>
				<div className="about-content">
					<p>
						N-TECH Systems is an Innovative IT Company. We empower young people
						with the relevant IT programs for securing jobs and to be
						self-reliant. N-TECH offers 80% Practicals class and hand-on
						training on all regular and Professional Courses which includes:{' '}
						<Link to="/courses/networking/networking">
							<b>Computer Networking</b>
						</Link>
						,{' '}
						<Link to="/courses/design/graphic-design-branding">
							<b>Graphics Design</b>
						</Link>{' '}
						,
						<Link to="/courses/web/web-design">
							<b>Web Design</b>
						</Link>
						,{' '}
						<Link to="/courses/software/programming">
							<b>Software Development</b>
						</Link>
						,
						<Link to="/courses/cloud/cloud-computing">
							<b> Cloud computing </b>
						</Link>
						,{' '}
						<Link to="/courses/engineering/computer-engineering">
							<b>Computer Engineering</b>
						</Link>{' '}
						e.t.c.
					</p>
					<p>
						We also deliver world-class IT Consultancy services to Individual
						Businesses, Institutions, Company and Organizations. We are an
						innovative company providing professional IT Solutions which
						includes: Web Development, School Management Systems, Hospital
						Management System, E-Library, Networking, Internet Installations,
						E-learning e.t.c. with quality customer support system.
					</p>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
