import React from 'react';

import { Link } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './index.css';

import image1 from '../../assets/images/others/photo-11.jpg';
import image2 from '../../assets/images/others/photo-6.jpg';
import image3 from '../../assets/images/others/photo-15.jpg';

const CarouselComponent = () => {
	return (
		<section>
			<Carousel
				className="carousel-cont"
				showArrows={false}
				autoPlay={true}
				infiniteLoop={true}
				showThumbs={false}
			>
				<div className="carousel-slide-cont">
					<img className="carousel-img" alt="Carousel pictures" src={image1} />
					<div className="inner-text text-container hero-content">
						<h2> Learn Now! </h2> <h2> Earn Tommorow! </h2>
						<p>
							Whether you are completely new to coding or had prior experience,
							at N-TECH we will help you accelerate your dream of becoming a
							world-class software developer or designer in record time.
						</p>
						<Link to="/contact">
							<button className="btn"> Apply Now </button>
						</Link>
					</div>
				</div>
				<div>
					<img className="carousel-img" alt="Carousel pictures" src={image2} />
					<div className="inner-text text-container hero-content">
						<h2> Learn Now! </h2> <h2> Earn Tommorow! </h2>
						<p>
							Whether you are completely new to coding or had prior experience,
							at N-TECH we will help you accelerate your dream of becoming a
							world-class software developer or designer in record time.
						</p>
						<Link to="/contact">
							<button className="btn"> Apply Now </button>
						</Link>
					</div>
				</div>
				<div>
					<img className="carousel-img" alt="Carousel pictures" src={image3} />
					<div className="inner-text text-container hero-content">
						<h2> Learn Now! </h2> <h2> Earn Tommorow! </h2>
						<p>
							Whether you are completely new to coding or had prior experience,
							at N-TECH we will help you accelerate your dream of becoming a
							world-class software developer or designer in record time.
						</p>
						<Link to="/contact">
							<button className="btn"> Apply Now </button>
						</Link>
					</div>
				</div>
			</Carousel>
			<div className="outer-text text-container hero-content">
				<h2> Learn Now! </h2> <h2> Earn Tommorow! </h2>
				<p>
					Whether you are completely new to coding or had prior experience, at N
					- Tech we will help you accelerate your dream of becoming a world -
					class software developer or designer in record time.
				</p>
				<Link to="/contact">
					<button className="btn"> Apply Now </button>
				</Link>
			</div>
		</section>
	);
};

export default CarouselComponent;
