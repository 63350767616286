export const services = [{
    id: 1,
    title: 'Wireless internet broadband',
    image: require('./../../assets/images/services-icon/wifi.png'),
    description: 'We offer fast and reliable internet speed of 20Mbps with easy maintainance.'
  },
  {
    id: 2,
    title: 'Mobile App and Web Development',
    image: require('./../../assets/images/services-icon/mobile-web.png'),
    description: 'We build classic mobile applications that suites your specification and meets your requirement(s).'
  },
  {
    id: 3,
    title: 'Software Development',
    image: require('./../../assets/images/services-icon/software.png'),
    description: 'Development of software ranging fron e-commerce, school management, etc.'
  },
  {
    id: 4,
    title: 'Branding and Digital Printing',
    image: require('./../../assets/images/services-icon/printing.png'),
    description: 'We can help you brand your company, build stationaries and large format printing'
  },
  {
    id: 5,
    title: 'Remote Security Surveillance(CCTV)',
    image: require('./../../assets/images/services-icon/camera.png'),
    description: 'We sell and setup CCTV which can be monitored from mobile phones and also real time monitor at Office/home.'
  },
  {
    id: 6,
    title: 'Event Online Streaming',
    image: require('./../../assets/images/services-icon/streaming.png'),
    description: 'Stream your events live with N-Tech live streaming service to help reach thousands all over the world.'
  },
  {
    id: 7,
    title: 'Professional IT Training',
    image: require('./../../assets/images/services-icon/proffessional.png'),
    description: 'We offer varieties of IT courses to build up your career with explanatory practical class sessions'
  },
  {
    id: 8,
    title: 'N-Techub',
    image: require('./../../assets/images/services-icon/hub.png'),
    description: 'This is a community of techies and creatives.'
  },
]