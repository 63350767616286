import React from 'react';

// import Hero from '../../components/Hero';
import AboutUs from '../../components/AboutUs';
import Goals from '../../components/Goals';
import CarouselComponent from '../../components/CarouselComponent';
import GallerySlider from '../../components/GallerySlider';

const Home = () => {
	window.scrollTo({
		top: 0,
	});
	return (
		<div className="foot-cont">
			<CarouselComponent />
			{/* <Hero /> */}
			<AboutUs />
			<Goals />
			<GallerySlider />
		</div>
	);
};

export default Home;
