import React, { useState } from 'react';

import './style.css';

const Category = ({ categories, filterItems }) => {
	const [activeCategory, setActiveCategory] = useState('all');

	return (
		<div className="categories-nav">
			{categories.map((category, index) => {
				return (
					<button
						type="button"
						className={
							activeCategory === category ? `filter-btn active` : `filter-btn`
						}
						key={index}
						onClick={() => {
							filterItems(category);
							setActiveCategory(category);
						}}
					>
						{category}
					</button>
				);
			})}
		</div>
	);
};

export default Category;
