import React from 'react';
import { Link } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa';
import { IoCall } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';

import './index.css';

const Footer = () => {
	return (
		<div className="foot">
			<footer className="footer-container container">
				<div className="footer-card">
					<h5>Contact Us</h5>
					<div className="line-container">
						<IoCall className="icon-card" />
						<p>(+234) 915-915-0000</p>
					</div>
					<div className="line-container">
						<MdEmail className="icon-card" />
						<p>contactus@ntechsystems.com.ng</p>
					</div>
					<h5 className="addrress-title">Address</h5>
					<div className="line-container">
						{/* <IoLocation className="icon-card" /> */}
						<div className="address">
							<p>
								231, Ademulegun Road, Beside NEPA Office, Ondo City, Ondo State.
							</p>
							<p>
								68, Adjacent Jubilee Grammar School, Surulere, Ondo City, Ondo
								State
							</p>
							<p>205, Oyemekun Road, Lafe Junction, Akure, Ondo State.</p>
						</div>
					</div>
					<div className="socials">
						<a href="http://www.facebook.com/NtechInformationSystems">
							<FaFacebook className="social-icon" />
						</a>
						<a href="https://twitter.com/ntechsystems">
							<AiFillTwitterCircle className="social-icon" />
						</a>
						<a href="https://www.instagram.com/ntechsystems/">
							<AiFillInstagram className="social-icon" />
						</a>
					</div>
				</div>
				<div className="footer-card">
					<h5>Quick Links</h5>
					<ul className="quick-links">
						<li>
							<Link className="foot-link" to="/">
								Home
							</Link>
						</li>
						<li>
							<Link className="foot-link" to="/about">
								About
							</Link>
						</li>
						<li>
							<Link className="foot-link" to="/courses">
								Courses
							</Link>
						</li>
						<li>
							<Link className="foot-link" to="/services">
								Services
							</Link>
						</li>
						<li>
							<Link className="foot-link" to="/contact">
								Contact
							</Link>
						</li>
					</ul>
				</div>
				<div className="footer-card">
					<h5>About Us</h5>
					<p>
						We are creating Platform for empowering people through delivering
						Professional IT training to broad spectrum of people ranging from
						students seeking a career in computers to IT professionals requiring
						advanced skills or managers giving their careers an edge.
					</p>
				</div>
			</footer>
			<div className="footer-bottom">
				<p>Copyright © 2022. N-TECH Systems. All Rights Reserved</p>
			</div>
		</div>
	);
};

export default Footer;
