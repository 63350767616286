import React from 'react';

import { IoCall } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';

import './index.css';

const Contact = () => {
	window.scrollTo({
		top: 0,
	});
	return (
		<section className="section-container foot-cont">
			<div className="section-nav">
				<h1>Contact</h1>
			</div>
			<div className="contact-section container">
				<div className="left-section left-contact-box">
					<div className="footer-card details-card">
						<h5>Contact Us</h5>
						<div className="line-container">
							<IoCall className="icon-card" />
							<p className="contact-text">(+234) 915-915-0000</p>
						</div>
						<div className="line-container">
							<MdEmail className="icon-card" />
							<p className="contact-text">contactus@ntechwifi.com.ng</p>
						</div>
						<h5 className="addrress-title">Address</h5>
						<div className="line-container">
							{/* <IoLocation className="icon-card" /> */}
							<div className="address">
								<p>
									231, Ademulegun Road, Beside NEPA Office, Ondo City, Ondo
									State.
								</p>
								<p className="contact-text">
									68, Adjacent Jubilee Grammar School, Surulere, Ondo City, Ondo
									State
								</p>
								<p className="contact-text">
									205, Oyemekun Road, Lafe Junction, Akure, Ondo State.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="right-section">
					<form>
						<div className="form-field">
							<input
								type="text"
								className="form-control"
								placeholder="Enter your full name..."
							/>
						</div>
						<div className="form-field">
							<input
								type="text"
								className="form-control"
								placeholder="Enter your email..."
							/>
						</div>
						<div className="form-field">
							<input
								type="text"
								className="form-control"
								placeholder="Enter your phone number..."
							/>
						</div>
						<div className="form-field">
							<input
								type="text"
								className="form-control"
								placeholder="Enter your location..."
							/>
						</div>
						<div className="form-field">
							<input
								type="text"
								className="form-control"
								placeholder="Enter your course of interest..."
							/>
						</div>
						<div className="form-field">
							<textarea
								placeholder="Enter more notes..."
								cols="30"
								rows="10"
								className="form-control"
							></textarea>
						</div>
						<button type="submit" className="btn">
							SUBMIT
						</button>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Contact;
