import React, { useState } from 'react';

import Category from './components/Category';
import Course from './components/Course';

import { courses } from './data';
import './index.css';

const Courses = () => {
	window.scrollTo({
		top: 0,
	});

	const [allCourses, setCourses] = useState(courses);
	const categories = ['all', ...new Set(courses.map((data) => data.category))];

	const filterItems = (category) => {
		if (category === 'all') {
			return setCourses(courses);
		}

		const filteredCourses = courses.filter(
			(data) => data.category === category
		);
		setCourses(filteredCourses);
	};

	return (
		<section className="section-container foot-cont">
			<div className="section-nav">
				<h1>Courses</h1>
			</div>
			<div className="courses-section container">
				<Category categories={categories} filterItems={filterItems} />
				<Course courses={allCourses} />
			</div>
		</section>
	);
};

export default Courses;
