import React from 'react';

import vision from '../../assets/images/vision.png';
import mission from '../../assets/images/mission.png';
import values from '../../assets/images/values.png';

import './index.css';

const Goals = () => {
	return (
		<div>
			<section id="services" className="goals-container container">
				<div className="goal-card">
					<img src={vision} alt="" />
					<h4>Our Vision</h4>
					<p>
						N-TECH aspires be a world-class corporation providing excellent
						platform for I.T Training and Services for better performance and
						productivity
					</p>
				</div>
				<div className="goal-card">
					<img src={mission} alt="" />
					<h4>Our Mission</h4>
					<p>
						Empowerment of people through Technology for Better Productivity
						with IT to create solutions to all establishment for better
						performance and productivity.
					</p>
				</div>
				<div className="goal-card">
					<img src={values} alt="" />
					<h4>Our Values</h4>
					<p>
						We pose to deliver or Training and services in way that reflect us
						as a company of High Integrity with all our work showing simple
						touch of creativity and Professionalism.
					</p>
				</div>
			</section>
		</div>
	);
};

export default Goals;
