import React from 'react';

import './index.css';

import { services } from './data';

const Services = () => {
	window.scrollTo({
		top: 0,
	});
	return (
		<section className="section-container foot-cont">
			<div className="section-nav">
				<h1>Services</h1>
			</div>
			<div className="services-intro-text">
				<p>
					We deliver world-class IT Consultancy services to Individual
					Businesses, Institutions, Company and Organizations. We are an
					innovative company providing professional IT Solutions which includes:
				</p>
			</div>
			<div className="services-section container">
				{services.map((service) => {
					return (
						<div className="goal-card">
							<img src={service.image} alt="" />
							<h5>{service.title}</h5>
							<p>{service.description}</p>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default Services;
